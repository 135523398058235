<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card ref="formCard">
        <v-card-title>
          <span
            v-if="formType === 'UPDATE'"
            class="text-h5"
          >Update Wallet System</span>
          <span
            v-else
            class="text-h5"
          >Create new Wallet System</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-container grid-list-md>
              <v-row>
                 <v-col cols="12">
                  <v-text-field
                    v-model="form.code"
                    label="Code*"
                    :readonly="formType === 'UPDATE'"
                    required
                    :rules="[rules.requiredField]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.displayName"
                    label="Display Name*"
                    required
                    hint="?"
                    :rules="[rules.requiredField]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.baseUrl"
                    label="Base URL*"
                    required
                    :rules="[rules.requiredField]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.balanceEndpoint"
                    label="Balance Endpoint*"
                    required
                    :rules="[rules.requiredField]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.accountsEndpoint"
                    label="Accounts Endpoint"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.transactionEndpoint"
                    label="Transaction Endpoint"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.refundEndpoint"
                    label="Refund Endpoint"
                  />
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="form.isDataDriven"
                    :label="`Is Data Driven: ${form.isDataDriven ? form.isDataDriven.toString() : 'false'}`"
                  />
                </v-col>
                <v-col
                  v-if="form.isDataDriven"
                >
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.debitConsumerTopic"
                      label="Debit Consumer Topic*"
                      :required="form.isDataDriven"
                      :rules="form.isDataDriven ? [rules.requiredField] : []"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.debitProducerTopic"
                      label="Debit Producer Topic*"
                      :required="form.isDataDriven"
                      :rules="form.isDataDriven ? [rules.requiredField] : []"
                    />
                  </v-col>
                </v-col>
                <v-col v-else>
                  <v-text-field
                    v-model="form.debitEndpoint"
                    label="Debit Endpoint*"
                    :required="!isDataDriven"
                    :rules="!isDataDriven ? [rules.requiredField] : []"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    isShow: Boolean,
    formType: String,
    selectedItem: Object,
  },
  data() {
    return {
      dialog: this.isShow ?? false,
      isDataDriven: false,
      form: {
      },
      rules: {
        requiredField: (v) => !!v || 'Required field.',
      },
    };
  },
  computed: {
    populatedForm() {
      return {
        displayName: this.form.displayName,
        code: this.form.code,
        balanceEndpoint: this.form.balanceEndpoint,
        baseUrl: this.form.baseUrl,
        email: this.form.email,
        debitEndpoint: this.form.debitEndpoint,
        isDataDriven: this.form.isDataDriven,
        accountsEndpoint: this.form.accountsEndpoint,
        transactionEndpoint: this.form.transactionEndpoint,
        refundEndpoint: this.form.refundEndpoint,
        debitConsumerTopic: this.form.debitConsumerTopic,
        debitProducerTopic: this.form.debitProducerTopic,
        formType: this.formType,
      };
    },
  },
  watch: {
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    deep: true,
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    close() {
      this.dialog = false;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: 'smooth',
      });
      console.log(this.formType)
      this.$emit('close-dialog', this.formType);
    },
    save() {
      if (this.$refs.formEntry.validate()) {
        console.log(this.populatedForm)
        this.$emit('save-wallet', this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      if (this.formType === 'CREATED') {
        this.form = {
          displayName: undefined,
          code: undefined,
          balanceEndpoint: undefined,
          debitEndpoint: undefined,
          baseUrl: undefined,
          isDataDriven: false,
          accountsEndpoint: undefined,
          debitConsumerTopic: undefined,
          debitProducerTopic: undefined,
          transactionEndpoint: undefined,
          refundEndpoint: undefined,
        };
      } else if (this.formType === 'UPDATE' && this.selectedItem) {
        this.form = {
          displayName: this.selectedItem.displayName,
          code: this.selectedItem.code,
          balanceEndpoint: this.selectedItem.balanceEndpoint,
          baseUrl: this.selectedItem.baseUrl,
          email: this.selectedItem.email,
          debitEndpoint: this.selectedItem.debitEndpoint,
          isDataDriven: this.selectedItem.isDataDriven ?? false,
          accountsEndpoint: this.selectedItem.accountsEndpoint,
          debitConsumerTopic: this.selectedItem.debitConsumerTopic,
          debitProducerTopic: this.selectedItem.debitProducerTopic,
          transactionEndpoint: this.selectedItem.transactionEndpoint,
          refundEndpoint: this.selectedItem.refundEndpoint,
        };
      }
    },
  },
};
</script>
