<template>
<v-app id="inspire">
    <div class="px-4" v-if="hasPermission('AUTH_LIST_WALLET_SYSTEM')">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Wallet Systems</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between" >
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-text-field label="Code" v-model="query.code" single-line outlined @change="search($event, 'code')"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="Display Name" v-model="query.name" single-line outlined @change="search($event, 'name')"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6>
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="hasPermission('AUTH_CREATE_WALLET_SYSTEM')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showWalletSystemForm('CREATED')">Create Wallet System</v-btn>
                                <wallet-system-form ref="WalletSystemForm" :selected-item="selectedWalletSystem" :is-show="showFormDialog" :form-type="WalletSystemFormType" v-on:close-dialog="closeDialog" v-on:save-wallet="saveWalletSystem" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="displayWalletSystems"
              :page.sync="page"
              :items-per-page="rowsPerPage"
              hide-default-footer
              class="elevation-2 mt-4 px-4"
            >
              <template v-slot:[`item.controls`]="{ item }">
                  <v-icon
                    medium
                    color="green"
                    class="mr-2"
                    @click="viewUser(item)"
                  >
                    mdi-magnify-plus
                  </v-icon>

                  <v-icon
                    medium
                    color="green"
                    v-if="hasPermission('AUTH_UPDATE_WALLET_SYSTEM')"
                    @click="showWalletSystemForm('UPDATE', item)"
                  >
                    mdi-pencil
                  </v-icon>
              </template>
              <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                      Found no results.
                  </v-alert>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="numberOfPages"
              ></v-pagination>
            </div>
          </div>
        </template>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import WalletSystemForm from '../../components/WalletSystemForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'WalletSystem',
    components: {
        Loading,
        DetailsForm,
        WalletSystemForm
    },
    watch: {
      page: {
          handler() {
              this.queryData();
          },
          deep: true
      }
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'roles',
            'authorities'
        ]),
    },
    data() {
        return {
            headers: [],
            displayWalletSystems: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            WalletSystemFormType: 'CREATE',
            selectedWalletSystem: {},
            numberOfPages: 0,
            page: 1,
            rowsPerPage: 5,
            details: {
                title: '',
                item: {}
            },
            query: {
                code: '',
                name: ''
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
        }
    },
    async mounted() {
        this.setHeaders()
        console.log('2')
        this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.page = 1
            }
            let pageNumber = this.page - 1;
            try {
                const walletSystemResponse = await API.getWalletSystems(pageNumber, this.rowsPerPage, this.query)
                if (!walletSystemResponse || walletSystemResponse.error) {
                    //error getting data
                    console.log(`${walletSystemResponse.error}`)
                } else {
                  this.displayWalletSystems = walletSystemResponse.walletSystem
                  this.numberOfPages = walletSystemResponse.pageSize
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        showWalletSystemForm(what, item = {}) {
            this.WalletSystemFormType = what
            this.selectedWalletSystem = item
            this.showFormDialog = true
        },
        closeDialog(type) {
          console.log(type)
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        saveWalletSystem(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new Wallet System <strong><i>${value.code}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createWalletSystem(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                        } else {
                            this.loadingMessage = `Successfully created Wallet System <strong><i>${value.code}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.WalletSystemForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating new Wallet System <strong><i>${value.code}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateWalletSystem(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated Wallet System <strong><i>${value.code}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.WalletSystemForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewUser(walletSystem) {
            this.details.title = walletSystem.display_name
            this.details.item = walletSystem
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        },
        setHeaders() {
            var headers = [{
                    text: 'Code',
                    value: 'code',
                    align: 'center',
                },
                {
                    text: 'Display Name',
                    value: 'displayName',
                    align: 'center',
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    align: 'center',
                    sortable: false
                }
            ]
            this.headers = headers
        },
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
